.card_grid {
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    .card_grid {
        grid-template-columns: 1fr 1fr;
    }
}