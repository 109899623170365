footer {
    background: var(--color-dark-alt);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
}

.footer__logo {
    display: block;
    margin-bottom: 2rem;
}
.footer__logo > img {
    width: 255px;
    height: 135px;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1.2rem;
    gap: 1.5rem;
    margin: 0 auto 3rem;
}

.footer__socials {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}

.footer__socials a {
    background: var(--color-dark);
    color: var(--color-light);
    padding: 0.8rem;
    border-radius: 50%;
    display: flex;
    border: 1px solid transparent;
}
.footer__socials a:hover {
    color: var(--color-light);
    border-color: var(--color-dark);
    background: transparent;
}

.footer__copyright {
    margin-bottom: 1rem;
    color: var(--color-light);
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) { 
    .permalinks {
        flex-direction: column;
        gap: 1rem;
    }
    .footer__socials {
        margin-bottom: 2.6rem;
    }
    .footer__copyright {
        margin-bottom: 4rem;
    }
}