@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@100;200;300;400;500;900&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-dark: #2b2b2b;
    --color-dark-alt: #4f4e4e;
    --color-light: #EFEBE6;
    --color-light-alt: #cac6c1;
    --color-highlight: hsl(40, 18%, 52%);
    --color-highlight-alt: #84775e;
    --color-white: rgba(255, 255, 255, 0.9);

    --transition: all 200ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

* body {
    font-family: 'Gothic A1', sans-serif;
    background: var(--color-dark);
    color: var(--color-light);
    line-height: 1.7;
}

/* GENERAL STYLES */

.container {
    width: var(--container-width-lg);
    margin: 0 auto; /* Position in the middle */
}

.hide {
    display: none;
}

h1 {
    margin-top: 3rem;
}
h2 {
    margin-top: 3rem;
}

a {
    transition: var(--transition);
    padding: 0.5rem;
    color: var(--color-light);
}

a:hover {
    color: var(--color-dark);
}

.header__container {
    height: 100vh;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1024px) {
    body {
        background-size: 125vh;
    }
    .container {
        width: var(--container-width-md);
    }
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    body {
        background-size: 125vh;
    }
    .container {
        width: var(--container-width-sm);
    }
}