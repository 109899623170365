.section__product {
    display: grid;
    grid-template-columns: 40% 60%;
}

.single_product-left {
    display: flex;
}
.single_product-right {
    display: grid;
    grid-template-columns: 65% 35%;
    padding: 1.5rem;
}

.single_product-title {
    align-self: flex-start;
    font-size: 1.5rem;
}

.single_product-buy_section {
    border: 1px solid var(--color-highlight);
    padding: 1rem;
    margin: 1rem;
}

.single_product-buy_button {
    display: block;
    text-align: center;
    background: var(--color-highlight);
}

.single_product-buy_price {
    text-align: center;
}

.productSection__subtitle {
    font-weight: 200;
    margin-bottom: 0.5rem;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1024px) {
    .section__product {
        grid-template-columns: 50% 50%;
    }
    .single_product-right {
        display: flex;
        flex-direction: column;
    }
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    .section__product {
        display: contents;
    }
    .single_product-right {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
    }
}