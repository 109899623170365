/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (min-width: 670px) {
    .mobile-nav {
        display: none;
    }
    .desktop-nav {
        display: grid;
    }
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    .mobile-nav {
        display: flex;
    }
    .desktop-nav {
        display: none;
    }
}