a > img {
    height: 1.5rem;
}

/* DESKTOP VERSION */

.desktop-nav {
    display: grid;
    grid-template-columns: 1fr 3fr;
    z-index: 3;
    position: fixed;
    background: transparent;
    width: 100%;
    height: 2.6rem;
    padding: 0.5rem;
    left: 0;
    top: 0;
    transition: var(--transition);
}

.desktop-nav.active {
    background: var(--color-dark-alt);
}

.desktop-nav > .right > .section-link:hover {
    color: var(--color-light);
    padding: 1rem 3rem;
    background: var(--color-highlight);
}

.right {
    justify-self: end;
}