#header {
    overflow: hidden;
    background: var(--color-highlight);
}

.header_img-container {
    display: flex;
    padding: 1rem 30% 0.5rem 30%;
    justify-content: center;
}

.header_img {
    width: 100%;
}

.header_title {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    color: black;
    font-weight: 700;
    margin-bottom: 0.5rem;
}