.catalogue__container {
    padding-bottom: 5rem;
}

.catalogue__content {
    display: block;
    margin-bottom: 1rem;
}

.catalogue__links-container {
    z-index: 2;
    position: sticky;
    top: 2.5rem;
    bottom: 50%;
    background: var(--color-dark);
    display: flex;
    justify-content: center;
}

.catalogue__links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    text-align: center;
}

.catalogue__selected-category {
    border-bottom: 4px solid var(--color-highlight);
    padding: 0.1rem 0.5rem;
}
.catalogue__selected-category:hover {
    background: var(--color-dark-alt);
}
.catalogue__selected-category > a:hover {
    color: var(--color-light);
}
.catalogue__non-selected-category {
    padding: 0.1rem 0.5rem;
    border-bottom: 4px solid var(--color-dark-alt);
}
.catalogue__non-selected-category:hover {
    color: white;
    background: var(--color-dark-alt);
    border-bottom: 4px solid transparent;
}
.catalogue__non-selected-category > a:hover {
    color: white;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1024px) {
    .catalogue__banner {
        padding: 4rem 20% 0% 20%;
    }
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    .catalogue__links-container {
        top: 0;
    }
    .catalogue__banner {
        padding: 10% 10% 0% 10%;
    }
    .catalogue__title {
        font-size: 1.5rem;
        margin-bottom: 2rem;
    }
}