#banner {
    height: 100vh;
    overflow: hidden;
}

.header_banner-container {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.header-content {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0;
    justify-content: center;
    align-items: center;
}

.container-header_logo {
    width: 60%;
}

.container-header_logo > img {
    display: inline;
    width: 100%;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 1));
}

.header-scroll_down {
    color: white;
    font-weight: 900;
    text-align: center;
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 1));
}

.header-scroll_down-text {
    padding-bottom: 1rem;
}

.header-scroll_down-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.5rem;
    animation: scroll_down-jumps 3s ease-in-out infinite;
    bottom: 0px;
}

@keyframes scroll_down-jumps {
    0% {
        bottom: 0px;
    }
    6% {
        bottom: -10px;
    }
    12% {
        bottom: 0px;
    }
    16% {
        bottom: -10px;
    }
    24% {
        bottom: 0px;
    }
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1024px) {
    .container-header_logo {
        width: 80%;
    }
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    .header-scroll_down {
        bottom: 6rem;
    }
    .container-header_logo {
        width: 90%;
    }
}



