.contact__container {
    padding-top: 1rem;
    padding-bottom: 5rem;
}

.contact__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
}

.contact__option {
    background: var(--color-dark-alt);
    padding: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    background: var(--color-highlight);
    border-radius: 0.5rem;
    border: 1px solid transparent;
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
.contact__option a:hover {
    background: transparent;
    border-color: var(--color-highlight);
}
.contact-center{
    margin-top: 2rem;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

/* ======== MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 670px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}